/**
 * 租房管理模块的
 */
import { defineStore } from 'pinia'
const initState = () => ({
  houseBuildingId: null, // 当前编辑的楼盘id
  houseRoomId: null, // 当前编辑的房间id
  contractForm: {},
  adjustmentParams: {}, // 租金调整参数
  adjustBills: [], // 租金调整
  curExpense: {}, // 当前编辑的收费项目
  roomExpense: [], // 收费项目
})

export const useRentalStore = defineStore(
  'rental',
  () => {
    const rentalData = ref({ ...initState() })
    // 获取状态
    const getRentalData = (key) => {
      // 检查 rentalData.value 中是否存在该 key
      if (!(key in rentalData.value)) {
        throw new Error(`属性 "${key}" 在 rentalData.value 中不存在`)
      }
      // 返回对应的值
      return rentalData.value[key]
    }

    // 设置状态
    const setRentalData = (key, val) => {
      rentalData.value[key] = val
    }
    // 添加费用项目
    const pushRoomExpense = (val) => {
      rentalData.value.roomExpense.push(val)
    }
    // 删除费用项目
    const delRoomExpense = (index) => {
      rentalData.value.roomExpense.splice(index, 1)
    }
    // 重置租金调整
    const resetRentAdjust = () => {
      rentalData.value.adjustBills = []
    }
    const resetState = () => {
      rentalData.value = { ...initState() }
    }
    return {
      rentalData,
      resetState,
      setRentalData,
      getRentalData,
      pushRoomExpense,
      delRoomExpense,
      resetRentAdjust,
    }
  },
  {
    persist: true,
  },
)
